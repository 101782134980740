import scrollIntoView from 'scroll-into-view';

// used for horizontally scrolling active nav link into center of viewport
export default function scrollNavToLink(navLinkId) {
  const navLink = document.getElementById(navLinkId);

  const isIE11Client = !!window.MSInputMethodContext && !!document.documentMode;

  if (isIE11Client) {
    // standard browser implementation
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
    navLink.scrollIntoView();
  } else {
    scrollIntoView(navLink, {
      validTarget(scrollingParent) {
        // don't scroll window, only nav
        return scrollingParent !== window;
      }
    });
  }
}
