import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';
import { Container, Row, Col } from 'reactstrap';
import { Link, Events } from 'react-scroll';
import { gray3, lumosWhite, orange4, black, gray6 } from '@lumoslabs/react_lux';

import { stdPadding } from 'components/ui';
import i18n from 'utils/i18n';
import { mediaQuery } from 'styles/helpers/layout';
import { urlSlugToI18nKey } from '../../utilities';
import scrollNavToLink from './scrollNavToLink';

const navOffset = -140;
const bacI18nKeyPrefix = 'Games.brainAttributeCategories';

const borderStyle = `1px solid ${gray3}`;
const styles = StyleSheet.create({
  navWrapper: {
    borderBottom: borderStyle,
    borderTop: borderStyle,
    zIndex: 2,
    backgroundColor: lumosWhite
  },
  navContainer: {
    [mediaQuery.maxWidth.tablet]: {
      marginLeft: 0,
      marginRight: 0
    }
  },
  innerNavWrapper: {
    [mediaQuery.maxWidth.tablet]: {
      flexWrap: 'nowrap',
      overflowX: 'auto',
      width: '100vw',
      '::-webkit-scrollbar': {
        display: 'none'
      }
    }
  },
  navItem: {
    textAlign: 'center',
    [mediaQuery.maxWidth.tablet]: {
      flex: '0 1 auto'
    }
  },
  navLink: {
    cursor: 'pointer'
  },
  text: {
    display: 'inline-block',
    textTransform: 'uppercase',
    fontSize: '0.9em',
    whiteSpace: 'nowrap',
    padding: `${stdPadding * 0.75}px 0`,
    color: gray6
  },
  active: {
    borderBottom: `4px solid ${orange4}`,
    color: black
  }
});

function CategoryNavigation({ bacSlugsOrdered, locale }) {
  const [activeSlug, setActiveSlug] = useState(bacSlugsOrdered[0]);
  const [pageIsScrolling, setPageIsScrolling] = useState(false);

  useEffect(() => {
    Events.scrollEvent.register('begin', () => setPageIsScrolling(true));
    Events.scrollEvent.register('end', () => setPageIsScrolling(false));
    return function cleanup() {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  });

  function handleSetActive(slug) {
    // This prevents the 'active' css and animations from jumping to each
    // link between current category and new category when clicking a nav link
    if (pageIsScrolling) return;
    setActiveSlug(slug);
    scrollNavToLink(`${slug}-nav-item`);
  }

  const navItems = bacSlugsOrdered.map((slug) => {
    // Hide the Word category in navbar on non-English pages
    if (locale !== 'en' && slug === 'word') return null;
    const i18nKey = `${bacI18nKeyPrefix}.${urlSlugToI18nKey(slug)}`;
    return (
      <Col key={slug} id={`${slug}-nav-item`} className={css(styles.navItem)}>
        <Link
          className={css(styles.navLink)}
          to={`${slug}-games`}
          href={`#${slug}-games`}
          spy
          hashSpy
          smooth
          offset={navOffset}
          duration={500}
          role="presentation"
          onSetActive={() => handleSetActive(slug)}
          onClick={() => handleSetActive(slug)}
        >
          <div className={css(styles.text, activeSlug === slug && styles.active)}>{i18n.t(i18nKey)}</div>
        </Link>
      </Col>
    );
  });

  return (
    <div className={css(styles.navWrapper)}>
      <Container className={css(styles.navContainer)}>
        <Row className={css(styles.innerNavWrapper)}>{navItems}</Row>
      </Container>
    </div>
  );
}

CategoryNavigation.propTypes = {
  bacSlugsOrdered: PropTypes.arrayOf(PropTypes.string).isRequired,
  locale: PropTypes.string.isRequired,
  // Purposely leaving this in for possible later use. This was originally added to possibly adjust for the nav
  // being sticky during scroll nav (where you would want the height of the nav bar to be accounted for in an offset).
  // It turned out that just adding state management in the parent component was enough to "fix" it
  // eslint-disable-next-line react/no-unused-prop-types
  sticky: PropTypes.bool.isRequired
};

export default CategoryNavigation;
