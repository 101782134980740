import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Container, Row, Col } from 'reactstrap';
import { RoundedButton, whiteAt98, layout as layoutStyles } from '@lumoslabs/react_lux';

import { HeaderText, stdMargin } from 'components/ui';
import i18n from 'utils/i18n';

import { buttonStyles } from 'styles/helpers';

import background from '../images/background-circle-dots-and-atoms.svg';

export const eclipseHeroId = 'games-index-header';

const styles = StyleSheet.create({
  heroWrapper: {
    backgroundImage: `url(${background})`,
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    minHeight: 522,
    textAlign: 'center'
  },
  heroText: {
    color: whiteAt98
  },
  header: {
    fontWeight: 700,
    marginBottom: stdMargin
  },
  subheader: {
    marginBottom: stdMargin * 1.5
  },
  cta: {
    margin: '0 auto'
  }
});

function EclipseHero({ header, subheader }) {
  return (
    <div className={css(styles.heroWrapper, layoutStyles.centerVertical)} id={eclipseHeroId}>
      <Container>
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <HeaderText size={1} styles={[styles.heroText, styles.header]}>
              {header}
            </HeaderText>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <HeaderText size={5} styles={[styles.heroText, styles.subheader]}>
              {subheader}
            </HeaderText>
          </Col>
        </Row>
        <Row>
          <RoundedButton
            value={i18n.t('Games.GameIndexPage.EclipseHero.cta')}
            styles={[buttonStyles.buttonDefaults, styles.cta]}
            track="true"
            trackButtonPress="goto_signup_get_started"
            trackLocation="body"
            href="/sign_up"
          />
        </Row>
      </Container>
    </div>
  );
}

EclipseHero.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired
};

export default EclipseHero;
