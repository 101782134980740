export function urlSlugToI18nKey(urlSlug) {
  const segments = urlSlug.split('-');
  return segments
    .map((segment, index) => {
      if (index === 0) return segment;
      return segment.charAt(0).toUpperCase() + segment.slice(1);
    })
    .join('');
}

export default urlSlugToI18nKey;
