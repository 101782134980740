import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';
import { Container, Row, Col } from 'reactstrap';
import { HeaderText } from 'components/ui';

const styles = StyleSheet.create({
  wrapper: {
    margin: '40px 0'
  },
  header: {
    textAlign: 'center'
  }
});

function CategorySectionHeader({ text }) {
  return (
    <Container>
      <Row className={css(styles.wrapper)}>
        <Col md={{ size: 6, offset: 3 }}>
          <HeaderText styles={[styles.header]} size={4}>
            {text}
          </HeaderText>
        </Col>
      </Row>
    </Container>
  );
}

CategorySectionHeader.propTypes = {
  text: PropTypes.string.isRequired
};

export default CategorySectionHeader;
