import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'gatsby';
import { orange4, fontStyles } from '@lumoslabs/react_lux';
import { css, StyleSheet } from 'aphrodite';

import i18n from 'utils/i18n';
import { ArrowIcon } from 'components/ui/icons';
import { stdMargin, stdPadding } from 'components/ui';
import GameCardCollection from '../shared/GameCardCollection';

const bacI18nKeyPrefix = 'Games.GameIndexPage.BrainCategoryOverview';
const linkColor = orange4;

const styles = StyleSheet.create({
  subheader: {
    marginTop: stdMargin / 4
  },
  linkColumn: {
    textAlign: 'right',
    marginTop: stdMargin / 4
  },
  link: {
    position: 'relative',
    color: linkColor,
    paddingRight: stdPadding * 1.5
  },
  arrow: {
    position: 'absolute',
    bottom: -5,
    right: 0
  },
  gameCardsWrapper: {
    marginTop: stdMargin / 2,
    marginBottom: stdMargin * 1.5
  }
});

function BrainCategoryOverview({ i18nKey, urlSlug, locale, games, categorySubheader, sectionStyles }) {
  const categoryString = i18n.t([bacI18nKeyPrefix, 'headers', i18nKey].join('.'));

  return (
    <section id={`${urlSlug}-games`} className={css(sectionStyles)}>
      <Container>
        <Row>
          <Col xs={4}>
            <h2
              /* using an h2 tag for SEO purposes, but with h5 styles */
              className={css(fontStyles.h5)}
            >
              {categoryString}
            </h2>
          </Col>
          <Col xs={8} className={css(styles.linkColumn)}>
            <Link to={`/${locale}/brain-games/${urlSlug}-games/`} className={css(styles.link)}>
              <span>{i18n.t([bacI18nKeyPrefix, 'categoryLinks', i18nKey].join('.'))}</span>
              <ArrowIcon fill={linkColor} altText="some alt text" orientation="right" styles={[styles.arrow]} />
            </Link>
          </Col>
        </Row>
        <Row className={css(styles.subheader)}>
          <Col md={7}>{categorySubheader}</Col>
        </Row>
      </Container>
      <GameCardCollection locale={locale} games={games} wrapperStyles={[styles.gameCardsWrapper]} />
    </section>
  );
}

BrainCategoryOverview.propTypes = {
  i18nKey: PropTypes.string.isRequired,
  urlSlug: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  categorySubheader: PropTypes.string.isRequired,
  games: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      longDescription: PropTypes.shape({
        longDescription: PropTypes.string
      })
    })
  ).isRequired,
  sectionStyles: PropTypes.arrayOf(PropTypes.object)
};

BrainCategoryOverview.defaultProps = {
  sectionStyles: []
};

export default BrainCategoryOverview;
