import PropTypes from 'prop-types';

export default {
  location: PropTypes.shape({
    href: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired
  }),
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    pagePath: PropTypes.string.isRequired
  })
};
