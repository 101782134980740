import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

import i18n from 'utils/i18n';

const rotationAmounts = {
  up: 0,
  right: 90,
  down: 180,
  left: 270
};

function ArrowIcon({ fill, altText, orientation, styles }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      aria-labelledby="arrowIconTitle"
      className={css(styles)}
    >
      <title id="arrowIconTitle">{altText || i18n.t(`Icons.ArrowIcon.defaultAltText.${orientation}`)}</title>
      <path
        transform={`rotate(${rotationAmounts[orientation]} 14 14)`}
        fill={fill}
        d="M12.9928932,9.82132071 L7.29999994,15.5142142 C6.90947566,15.9047385 6.27631068,15.9047385 5.88578638,15.5142142 C5.49526208,15.1236899 5.49526207,14.490525 5.88578636,14.1000007 L13.9928935,5.99289324 L22.1000002,14.1000007 C22.4905244,14.490525 22.4905244,15.12369 22.1000001,15.5142142 C21.7094758,15.9047385 21.0763108,15.9047385 20.6857865,15.5142142 L14.9928932,9.82132034 L14.9928932,22.0071068 L12.9928932,22.0071068 L12.9928932,9.82132071 Z"
      />
    </svg>
  );
}

ArrowIcon.propTypes = {
  fill: PropTypes.string.isRequired,
  altText: PropTypes.string,
  orientation: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  styles: PropTypes.arrayOf(PropTypes.object)
};

ArrowIcon.defaultProps = {
  orientation: 'up',
  styles: [],
  altText: ''
};

export default ArrowIcon;
