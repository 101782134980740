import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { lumosDarkBlue } from '@lumoslabs/react_lux';
import Sticky from 'react-stickynode';
import { StyleSheet } from 'aphrodite';

import Layout from 'components/layout';
import Metatags from 'components/Metatags';
import { headerId } from 'components/ui/Header';
import SchemaDotOrg from 'components/landing/SchemaDotOrg';
import TestimonialCardGroup from 'components/landing/TestimonialCardGroup';
import GatsbyPropTypes from 'utils/GatsbyPropTypes';

import BrainCategoryOverview from './BrainCategoryOverview';
import CategoryNavigation from './CategoryNavigation';
import CategorySectionHeader from './CategorySectionHeader';
import EclipseHero from './EclipseHero';
import { urlSlugToI18nKey } from '../utilities';

const bacSlugsOrdered = ['speed', 'memory', 'attention', 'flexibility', 'problem-solving', 'word', 'math'];

const slugsToI18nKeys = {};
bacSlugsOrdered.forEach(urlSlug => {
  slugsToI18nKeys[urlSlug] = urlSlugToI18nKey(urlSlug);
});

const styles = StyleSheet.create({
  firstCategory: {
    marginTop: 40
  }
});

function GameIndexPage({ location, pageContext, data: { contentfulGameIndexPage: content } }) {
  const [sticky, setSticky] = useState(false);

  const handleStateChange = status => {
    // `Sticky` technically has a third state, but only worrying about sticky/not sticky for now
    if (status.status !== Sticky.STATUS_ORIGINAL) {
      setSticky(true);
      return;
    }
    setSticky(false);
  };

  const { locale, pagePath } = pageContext;
  const {
    header,
    subheader: { subheader },
    categorySectionHeading,
    openGraphImage: { openGraphCard, twitterImage }
  } = content;

  const bacSections = bacSlugsOrdered.map((slug, idx) => {
    // Hide the Word category on non-English pages
    if (locale !== 'en' && slug === 'word') return null;
    const i18nKey = slugsToI18nKeys[slug];
    const possibleSubheaderNode = content[`${i18nKey}Subheader`];
    const categorySubheader = possibleSubheaderNode ? possibleSubheaderNode[`${i18nKey}Subheader`] : '';
    const isFirstCategory = idx === 0;
    return (
      <BrainCategoryOverview
        key={i18nKey}
        i18nKey={i18nKey}
        urlSlug={slug}
        locale={locale}
        games={content[`${i18nKey}Games`]}
        categorySubheader={categorySubheader}
        // adds margin between category navigation and first category
        // to prevent using marginBottom on the nav itself (which causes
        // an invisible margin to follow the nav while sticky)
        sectionStyles={isFirstCategory ? [styles.firstCategory] : []}
      />
    );
  });
  return (
    <Layout
      location={location}
      locale={locale}
      accentColor={lumosDarkBlue}
      isStickyHeader
    >
      <Metatags
        title={header}
        description={categorySectionHeading}
        type="article"
        canonical={pagePath}
        locale={locale}
        thumbnail={openGraphCard.src}
        imageWidth={openGraphCard.width}
        imageHeight={openGraphCard.height}
        twitterImage={twitterImage.url}
      />
      <EclipseHero header={header} subheader={subheader} />
      <CategorySectionHeader text={categorySectionHeading} />
      <Sticky top={`#${headerId}`} innerZ={2} onStateChange={handleStateChange}>
        <CategoryNavigation bacSlugsOrdered={bacSlugsOrdered} locale={locale} sticky={sticky} />
      </Sticky>
      {bacSections}
      <TestimonialCardGroup />
      <SchemaDotOrg />
    </Layout>
  );
}

// This query makes the assumption that there will ever be only one instance of contentfulGameIndexPage
// if someone adds a second GameIndexPage then I believe that will become the page at /en/brain-games
export const pageQuery = graphql`
  query GameIndexPageQuery($locale: String!) {
    contentfulGameIndexPage(node_locale: { eq: $locale }) {
      header
      subheader {
        subheader
      }
      categorySectionHeading
      attentionGames {
        ...gameCardFragment
      }
      flexibilityGames {
        ...gameCardFragment
      }
      memoryGames {
        ...gameCardFragment
      }
      problemSolvingGames {
        ...gameCardFragment
      }
      speedGames {
        ...gameCardFragment
      }
      wordGames {
        ...gameCardFragment
      }
      mathGames {
        ...gameCardFragment
      }
      attentionSubheader {
        attentionSubheader
      }
      flexibilitySubheader {
        flexibilitySubheader
      }
      memorySubheader {
        memorySubheader
      }
      problemSolvingSubheader {
        problemSolvingSubheader
      }
      speedSubheader {
        speedSubheader
      }
      wordSubheader {
        wordSubheader
      }
      mathSubheader {
        mathSubheader
      }
      openGraphImage {
        openGraphCard: fixed(width: 1200, height: 630) {
          aspectRatio
          width
          height
          src
        }
        twitterImage: file {
          url
        }
      }
    }
  }
`;

GameIndexPage.propTypes = {
  location: GatsbyPropTypes.location.isRequired,
  pageContext: GatsbyPropTypes.pageContext.isRequired,
  data: PropTypes.shape({
    contentfulGameIndexPage: PropTypes.shape({
      header: PropTypes.string.isRequired,
      categorySectionHeading: PropTypes.string.isRequired,
      attentionGames: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired
        })
      )
    })
  }).isRequired
};

export default GameIndexPage;
